import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Icon = makeShortcode("Icon");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "social-graphics"
      }}>{`Social Graphics`}</h1>
      <Grid width="60rem" gridTemplateColumns="15rem" gridTemplateRows="10rem" mt={1} mb={2} mdxType="Grid">
        <Box justifyContent="center" alignItems="center" bg="blueDark" mdxType="Box">
    <Icon color="white" height="8em" name="rebel-pin" width="6rem" mdxType="Icon" />
        </Box>
      </Grid>
      <Text variant="giga" mdxType="Text">Avatars:</Text>
      <p>{`Social avatar should be updated to Rebel pin on blue background`}</p>
      <Text variant="giga" mdxType="Text">Instagram/Social graphic variations:</Text>
      <p>{`Alternate posts with block variations below. See design example below.`}</p>
      <Text variant="mega" mdxType="Text"><strong>Text and logo on colour background used for: </strong>Testimonials, company announcements, company tips/Rebel academy tips.</Text>
      <Text variant="mega" mdxType="Text"><strong>Image and text used for: </strong>Product sales, product announcements, company announcements when we want to show an employee in the image.</Text>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      